import { SET_PAGE_HEADING } from './app-action-constants';

const initialState = {
  pageHeading: `Hi, I'm Will`
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case SET_PAGE_HEADING: {
      return {
        ...state,
        pageHeading: action.pageHeading
      };
    }

    default:
      return state;
  }
}
