import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import app from './app/app-reducer';

const configureStore = () => {
  let middlewares = [thunkMiddleware];

  if (process.env.NODE_ENV === 'development') {
    middlewares.push(
      createLogger({
        collapsed: true,
        duration: true
      })
    );
  }

  const store = createStore(
    combineReducers({
      app
    }),
    applyMiddleware(...middlewares)
  );

  return store;
}

export default configureStore;